<template>
  <div class="inputSearch">
    <button class="icon icon-input" :class="icon" @click="handleToggleClick"></button>
    <input type="text" v-model="inputValue" :placeholder="placeholder" @focus="handleToggleClick">
    <ul class="inputSearch-ul" :class="classComputed" @click='handleToggleClick'>
      <li v-for="(item, index) in dataFiltered" :key="index" class="inputSearch-ul__li">
        <a :href="item.url" v-html="item.label" @click="handleAnchorClick(item.label)"></a>
      </li>
    </ul>
    <div class="invisible" v-if="focusToggle" @click="handleToggleClick"></div>
  </div>
</template>

<script>
export default {
    props: {
      options:{ 
        type: Array,
        default: () => []
      },
      placeholder: {
        type: String,
        default:'Search'
      },
      icon: {
        type: String,
        default: ''
      }
    
    },
    data() {
      return {
        inputValue: '',
        focusToggle: false
      }
    },
    computed:{
      dataFiltered(){
        if(!this.inputValue) return this.options.sort((a,b) => a.label.localeCompare(b.label))
        const filter = JSON.parse(JSON.stringify(this.options)).filter(el => el.label.toLowerCase().includes(this.inputValue.toLowerCase()));
        filter.forEach( el => {
          const regex = new RegExp(this.inputValue, 'gi')
          el.label = el.label.replace(regex, (str) => {
            return `<span class="text-blue">${str}</span>`
          })
        })
        
        return filter.sort((a,b) => a.label.localeCompare(b.label))
      },
      classComputed(){
        if(!this.focusToggle) return
        return 'activated'
      }

    },
    methods: {
      handleToggleClick(){
        this.focusToggle = !this.focusToggle
      },
      handleAnchorClick(txt){
        this.inputValue = txt.replace(/<[^>]+>/g, '')
      }
    }
}
</script>

<style>

</style>